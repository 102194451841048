.home section {
  padding-top: 50px;
  padding-bottom: 50px; }

.home .wrapper-bg-black {
  background: #1A1A1A; }
  .home .wrapper-bg-black h3, .home .wrapper-bg-black h5, .home .wrapper-bg-black p {
    color: #FEFEFE; }

.home .frameWrapperApp {
  height: 745px;
  position: relative; }
  .home .frameWrapperApp .frame {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; }
    .home .frameWrapperApp .frame .cover {
      height: 100%; }
  .home .frameWrapperApp .frameUrl {
    position: absolute;
    left: 50.1%;
    top: 19px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow-x: hidden;
    overflow-Y: hidden;
    width: 318px;
    z-index: 2;
    height: 680px;
    border-radius: 25px; }
    .home .frameWrapperApp .frameUrl iframe {
      height: 680px;
      width: 318px; }

.home #home {
  position: relative;
  height: 930px;
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .home #home {
      height: 700px; } }
  @media screen and (max-width: 575px) {
    .home #home {
      height: 625px; } }
  @media screen and (max-width: 399px) {
    .home #home {
      height: 670px; } }
  .home #home .wrapper-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 550px;
    background: #1A1A1A;
    border-radius: 0 0 75% 25%;
    margin-left: -70px;
    margin-right: -70px; }
    @media screen and (max-width: 991px) {
      .home #home .wrapper-bg {
        height: 350px; } }
  .home #home .iframe {
    position: relative; }
    @media screen and (max-width: 991px) {
      .home #home .iframe {
        display: none; } }
    .home #home .iframe img {
      top: 40px;
      width: 350px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
  .home #home h1 {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 165px; }
    @media screen and (max-width: 991px) {
      .home #home h1 {
        margin-top: 60px; } }
    @media screen and (max-width: 991px) {
      .home #home h1 {
        text-align: center; } }
  @media screen and (max-width: 991px) {
    .home #home .dropdown {
      text-align: center; } }
  .home #home .dropdown-toggle {
    font-size: 13px;
    font-weight: 600; }
  .home #home .dropdown .dropdown-item {
    display: flex;
    align-items: center; }
    .home #home .dropdown .dropdown-item img {
      height: 16px;
      margin-right: 4px; }
  .home #home .stories {
    height: 80px;
    margin-top: 195px;
    flex-wrap: wrap; }
    .home #home .stories img {
      margin-right: 10px;
      max-width: 100px;
      max-height: 50px;
      margin-bottom: 10px; }
    @media screen and (max-width: 991px) {
      .home #home .stories {
        justify-content: center;
        margin-top: 130px; } }
    @media screen and (max-width: 575px) {
      .home #home .stories {
        justify-content: center;
        margin-top: 130px; }
        .home #home .stories img {
          margin-right: 5px;
          max-width: 90px;
          max-height: 40px;
          margin-bottom: 5px; } }

.home #features ul {
  justify-content: center; }
  .home #features ul li {
    text-align: center;
    padding-bottom: 16px; }
    .home #features ul li .wrapper-img {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: #e0e0e0;
      height: 100px;
      width: 100px;
      margin: auto; }
      .home #features ul li .wrapper-img img {
        height: 30px; }
    .home #features ul li h3 {
      margin-top: 10px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center; }

.home #features .frame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; }
  .home #features .frame .cover.ios {
    height: 737px; }
  .home #features .frame .cover.android {
    height: 100%; }
  .home #features .frameWrapper {
    position: relative; }
    @media screen and (max-width: 575px) {
      .home #features .frameWrapper {
        display: none; } }
    .home #features .frameWrapper h3 {
      text-align: center;
      margin: 45px 0 30px;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 700; }
    @media screen and (max-width: 767px) {
      .home #features .frameWrapper .arrow {
        display: none; } }
    .home #features .frameWrapper .arrow-left {
      position: absolute;
      top: 160px;
      left: -30px; }
      .home #features .frameWrapper .arrow-left img {
        height: 35px; }
      .home #features .frameWrapper .arrow-left .text {
        position: absolute;
        top: -55px;
        left: -125px;
        width: 155px;
        text-align: center;
        font-weight: bold;
        font-style: italic; }
    .home #features .frameWrapper .arrow-right {
      position: absolute;
      top: 88px;
      right: -20px; }
      .home #features .frameWrapper .arrow-right img {
        height: 35px; }
      .home #features .frameWrapper .arrow-right .text {
        position: absolute;
        top: -65px;
        right: -140px;
        width: 155px;
        text-align: center;
        font-weight: bold;
        font-style: italic; }
    .home #features .frameWrapper .wrapper-pulse {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 3;
      transform: translateX(-50%); }
    .home #features .frameWrapper .pulse {
      position: absolute;
      top: 18px;
      right: -222px;
      z-index: 4;
      margin: 100px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #EB3424;
      cursor: pointer;
      box-shadow: 0 0 0 #EB3424;
      animation: pulse 1s infinite; }
    .home #features .frameWrapper .pulse:hover {
      animation: none; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(235, 89, 84, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 35px rgba(235, 89, 84, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(235, 89, 84, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(235, 89, 84, 0.4);
    box-shadow: 0 0 0 0 rgba(235, 89, 84, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 35px rgba(235, 89, 84, 0);
    box-shadow: 0 0 0 35px rgba(235, 89, 84, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(235, 89, 84, 0);
    box-shadow: 0 0 0 0 rgba(235, 89, 84, 0); } }
  .home #features .frameUrlAndroid {
    position: absolute;
    left: 49.8%;
    top: 82px;
    transform: translateX(-50%);
    overflow-x: hidden;
    overflow-Y: hidden;
    width: 332px;
    z-index: 2;
    height: 520px; }
    .home #features .frameUrlAndroid iframe {
      height: 520px;
      width: 332px; }
  .home #features .frameUrlIos {
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-51%);
    overflow-x: hidden;
    overflow-Y: hidden;
    width: 303px;
    z-index: 2;
    height: 512px; }
    .home #features .frameUrlIos iframe {
      height: 512px;
      width: 303px; }
  .home #features .frameWrapperAndroid {
    height: 745px;
    position: relative; }
  .home #features .frameWrapperIos {
    height: 715px;
    position: relative; }

.home #video video {
  max-height: 480px; }

.home #video video::-webkit-media-controls-fullscreen-button {
  display: none; }

.home #gallery {
  display: none;
  padding-bottom: 140px; }
  .home #gallery ul li img {
    height: 450px;
    border-radius: 5px;
    border: 1px solid #e0e0e0; }

.home #contact {
  padding-top: 130px;
  position: relative;
  height: 400px;
  overflow: hidden; }
  .home #contact .newsletter-header {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px; }
  .home #contact .newsletter-custom-arrow-down {
    position: absolute;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid white; }
  .home #contact .newsletter-button {
    position: absolute;
    top: 67px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
  .home #contact .wrapper-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 565px;
    z-index: 1;
    margin-top: 35px;
    background: #1A1A1A;
    border-radius: 50% 65% 0 0;
    margin-left: -115px;
    margin-right: -70px; }
  .home #contact .content {
    position: relative;
    z-index: 2;
    top: 70px; }
    .home #contact .content a {
      font-size: 18px;
      font-weight: 700;
      color: white; }
      .home #contact .content a:hover {
        color: #FEFEFE; }
